.btn-primary {
    background-color: #1B4B6D !important;
    border-color: #1B4B6D !important;
    font-size: 14px !important;
}

.btn-primary:hover {
    background-color: rgb(16, 29, 46) !important;
    border-color: rgb(16, 29, 46) !important;
    color: #fff !important;
    font-size: 14px !important;
}

.btn-secondary {
    background-color: #fff !important;
    border-color: #1B4B6D !important;
    color: #1B4B6D !important;
}

.btn-secondary:hover {
    background-color: #1B4B6D !important;
    border-color: #1B4B6D !important;
    color: #fff !important;
}

.btn-outline-dark {
    background-color: #fff !important;
    border-color: #1B4B6D !important;
    color: #1B4B6D !important;
}

.btn-outline-dark:hover {
    background-color: #1B4B6D !important;
    border-color: #1B4B6D !important;
    color: #fff !important;
}

table {
    font-size: 14px;
}

textarea {
    font-size: 14px !important;
}

.form-control {
    font-size: 14px !important;
}

.btn {
    font-size: 14px !important;
}

.dropdown-menu {
    font-size: 14px !important;
}

.adm-link {
    font-size: 14px !important;
    color: #1B4B6D !important;
}

.input-group-text {
    font-size: 14px !important;
}

.btn-link {
    color: #1B4B6D !important
}

.btn-link:hover {
    color: #f1a333 !important
}



.adm-link:hover {
    color: #f1a333 !important
}

.xantie-txt {
    font-size: 15px !important;
    color: #fff !important;

}

.logout {
    font-size: 15px !important;
    color: #f1a333 !important;
}

.logout:hover {
    font-size: 15px !important;
    color: #fff !important;
}

.table-80 {
    height: 80vh;
    overflow: auto;
}

.table-80 table {
    margin: 0 !important;
}

.table-60 {
    height: 60vh;
    overflow: auto;
}

.table-60 table {
    margin: 0 !important;
}

.table-40 {
    height: 40vh;
    overflow: auto;
}

.table-40 table {
    margin: 0 !important;
}

.fixed-column-header tr th {
    position: sticky;
    top: 0;
}