.title {
    text-align: center;
    margin-top: 1%;
}

.table {
    margin-left: 2%;
    margin-right: 2%;
}

.table-sm {
    margin-left: 1%;
    margin-right: 1%;
 }
 
 .table-header {
    font-weight: 400;
    color: #aad8f8;
 }

.attendance-tabs {
    margin-left: 2%;
    margin-right: 2%;
}

.edit {
    width: 20px;
}

.delete {
    width: 20px;
}