.nav-link-txt {
   color: #FFFFFF;
   font-weight: 500;
   font-size: 15px !important;
}

.nav-link-txt:hover {
   color: #f1a333;
}

.login-button {
  color: #f1a333 !important;
  font-weight: 500;
  text-decoration: underline;
  display: block;
  font-size: 15px !important;
}

.login-button:hover {
  color: #FFFFFF !important;
}

.logo {
  width: 6%;
  height: 6%;
  margin: 0.2em;
}
.login {
width: 25px;
height: 25px;
}

.sticky-top {
  align-items: center;
}

.navigation .nav-link {
  display: block;
  padding: .5em;
  color: white;
}
.navigation .nav-link:hover {
  color:#f1a333;
}
@media all and (max-width: 800px) {
  .navigation {
    justify-content: space-around;
  }
}
@media all and (max-width: 600px) {
  .navigation {
    flex-flow: column wrap;
    padding: 0;
  }
  .navigation .nav-link { 
    text-align: center; 
    padding: 10px;
    border-top: 1px solid rgba(255, 255, 255,0.3); 
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
  }
  .navigation li:last-of-type .nav-link {
    border-bottom: none;
  }
}
header {
  background:#1B4B6D;
  display:flex;
  justify-content:space-between;
}
nav {
  position:absolute;
  width:100%;
  background:#1B4B6D;
  display:none;
}
nav:hover {display:block;}
nav .nav-link {
  display:block;
  line-height:50px;
  text-align:center;
}
@media only screen and ( min-width: 768px )
{
  nav {
    position:static;
    display:flex;
    background:none;
    clear:none;
    flex:1;
    justify-content:flex-end;
  }
  nav:hover {display:flex;}
  nav .nav-link {padding:0 1em; color:#FFFFFF; }
  nav .nav-link:hover {color:#f1a333;}
}