
.btn-dropdown-primary {
    background-color: #1B4B6D !important;
    border-color: #1B4B6D !important;
    color: #fff !important;
    font-size: 14px !important;
    border-radius: 0%;
}

.btn-dropdown-selected {
    background-color: #fff !important;
    border-color: #1B4B6D !important;
    color: #1B4B6D !important;
    border-radius: 0%;
}