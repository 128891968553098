.dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  top: 0;
  visibility: hidden;
  margin-top: -1px;
}
.dropdown-menu li:hover .sub-menu {
  visibility: visible;
  
}
.dropdown:hover .dropdown-menu {
  display: block;
  max-height: 300px;
  overflow: auto;
}